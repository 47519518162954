import { useState } from "react";

export default function DetailPoint({ image, text, index }) {
    const [showAll, setShowAll] = useState(false);

    var flex_class = index % 2 !== 0 ? "flex-row-reverse" : "";
    var text_class = showAll ? "" : "multiline";

    return (
        <div onClick={() => setShowAll(!showAll)} className={"w-100 hstack gap-3 " + flex_class}>
            <div className={"w-15 rounded-5"}>
                <img className={"w-100"} src={image} alt="" />
            </div>
            <div className={"info " + text_class}>{text}</div>
        </div>
    )
}