import PhoneNo from "./PhoneNo";
import SectionHeader from "./SectionHeader";
import ShowOnBig from "./ShowOnBig";
import ShowOnSM from "./ShowOnSM";


export default function Contact() {
    return (
        <div className="align-self-center w-75 pt-5">
            <SectionHeader title={"Contact Us"} />
            <div className="d-flex flex-wrap">
                <div className="info col-12 col-md-6">
                    Please contact us for any Job Work or Production requirements. We are with our state of the art setup, we can fullfil all your requirements with highest satisfaction.
                </div>
                <div className="col-12 col-md-6">
                    <ShowOnBig>
                        <PhoneNo stack={"vstack"} />
                    </ShowOnBig>
                    <ShowOnSM>
                        <PhoneNo stack={"pt-3 hstack"} />
                    </ShowOnSM>
                </div>
            </div>
        </div>
    )
}