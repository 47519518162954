import DetailPoint from "./DetailPoint";
import SectionHeader from "./SectionHeader";

import production from "./images/production.png";
import crane from "./images/crane.png";
import cooling from "./images/water.png";
import storage from "./images/storage.png";
import grinder from "./images/crush.png";
import location from "./images/highway.png";
import rural from "./images/rular.png";
import outmidc from './images/outMidc.png';
import electric from "./images/electrical.png";
import transport from "./images/transport.png";
import equipment from "./images/equipment.png";

const points = [
    {
        image: production,
        text: "Our unit is built to house up to 7 injection moulding machines of 450 TON capacity. At 100% capacity the total daily production capacity of the unit will be about 12,000 KG of products."
    },
    {
        image: crane,
        text: "The unit is also equipped with 10 TON  Overhead Crane to fulfil any loading and unloading requirements as well as mould changing requirements."
    },
    {
        image: cooling,
        text: "With water tank which holds IL litters of water and a 150 TON cooling tower. We are ready to tackle any cooling requirements."
    },
    {
        image: storage,
        text: "Apart from the production space, we have enough space to for packing and storage of finished goods."
    },
    {
        image: grinder,
        text: "We also have in house grinder to re-grind the runner and damaged pieces. Which reduce the downtime of production and utilities almost 100% of the raw material"
    },
    {
        image: location,
        text: "The unit is located just outside of the city of Satara, Maharashtra on old Satara Pune national highway. The current AH-48 (Mumbai-Bangalore) is  merely 2 KM away from the unit location."
    },
    {
        image: rural,
        text: "The rural area and proximity to National Highway makes the unit easily accessible to all the major industrial zones."
    },
    {
        image: outmidc,
        text: "Though the unit is located outside of MIDC area, we have secured the connection from Industrial Feeder itself to continue the production without any breaks."
    },
    {
        image: electric,
        text: "We have a setup ready for load up to 400 KVA, with voltage stabilizer deployed for insuring continuous flawless production."
    },
    {
        image: transport,
        text: "We have contracted a transport provider for all our transport operations. Which give us 24x7 access to 2 vehicles of 10,000 KG load capacity."
    },
    {
        image: equipment,
        text: "The vehicles are customized to suit our needs. Making it easy for unloading raw material without need of any external equipment."
    }
]

export default function Unit() {
    return (
        <div className="align-self-center w-75">
            <SectionHeader title={"Satara Unit"} />
            <div className="vstack gap-5">
                {
                    points.map((point, index) => {
                        return (
                            <DetailPoint
                                image={point.image}
                                text={point.text}
                                key={index}
                                index={index} />
                        )
                    })
                }
            </div>
        </div>
    )
}