import locationSVG from "./images/factory_FILL0_wght400_GRAD0_opsz24.svg";

export default function Footer() {
    return (
        <div className="footer p-3 bg-primary">
            <div className="d-flex flex-wrap">
                <div className="col-12 col-md">
                    <img className="pe-2" src={locationSVG} alt="Location" />
                    Frengen Industries
                </div>
                <div className="col-12 col-md">
                    SN 116/12, Kondave, Satara, MH, India - 415002
                </div>
                <div className="col-12 col-md text-end">
                    Opankys Inc.
                </div>
            </div>
        </div>);
}