import { useState } from "react"

export default function Information() {
    const [showMore, setShowMore] = useState(false);

    var secondParaClassName = "info"
    var buttonText = "Show Less";

    if (!showMore) {
        secondParaClassName += " d-none";
        buttonText = "Show More";
    }

    return (
        <div className="align-self-center w-75 py-3 vstack">
            <p className="info">
                The world of plastic products, which can be moulded is infinite. Almost every industry requires some of the parts which are produced with injection moulding technology. We Frengen Industries are in commercial production of Plastic Injection Moulding products since the year 2018.
            </p>
            <p className={secondParaClassName}>
                There are variety of different raw material used for the production e.g., PVC, PP, etc. The machines to produce such parts are very advanced and highly automated. With minimum downtime and small changes, the same machines can produce parts from different raw materials.
            </p>
            <p className={secondParaClassName}>
                Currently we have deployed 4 machines of 250 TON in production. The machines are made by Ferromatik Milacron India Ltd. The total daily production rounding up to 3,500 KG and monthly production up to 85,000 KG.
            </p>
            <p className={secondParaClassName}>
                Using the same machines we can produce variety of products, e.g., Automobile dashboard components, Household Electrical products, medical products like plungers and syringes, Agricultural products as PVC fittings, etc.
            </p>
            <button
                type="button"
                onClick={() => setShowMore(!showMore)}
                className="btn btn-outline-primary ms-auto px-4">
                {buttonText}
            </button>
        </div>
    )
}