import Contact from "./Contact";
import Footer from "./Footer";
import HeroCompnent from "./hero";
import ImageList from "./imageList";
import Information from "./info";
import Unit from "./unit";


function App() {
  return (
    <div className="vstack gap-5 app">
      <HeroCompnent />
      <ImageList />
      <Information />
      <Unit />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
