export default function HeroCompnent() {
    return (
        <div className="vh-100 hero">
            <div className="hero-title text-center text-uppercase position-relative vstack">
                <div className="industries opacity-25 position-absolute translate-middle-x start-50">
                    Industries
                </div>
                <div>Frengen</div>
                <div className="catchp">
                    Crafting excellence since 2017
                </div>
            </div>
        </div>
    )
}