export default function PhoneNo({ stack }) {
    return (
        <div className={stack + " gap-2"}>
            <div className="container">
                <p className="info text-center text-nowrap">
                    Pankaj Sarda<br />77 41 88 99 11
                </p>
            </div>
            <div className="container">
                <p className="info text-center text-nowrap">
                    Anuj Sarda<br />99 22 64 56 11
                </p>
            </div>
        </div>
    )
}