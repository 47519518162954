import ShowOnBig from "./ShowOnBig";
import ShowOnSM from "./ShowOnSM";

import Picture1 from "./images/Picture1.jpg";
import Picture2 from "./images/Picture2.jpg";
import Picture3 from "./images/Picture3.jpg";
import Picture4 from "./images/Picture4.jpg";


const images = [
    { path: Picture1 },
    { path: Picture2 },
    { path: Picture3 },
    { path: Picture4 }
];

const scaleFactor = 60;

export default function ImageList() {
    return (
        <div className="align-self-center">
            <ShowOnBig>
                <div className="hstack gap-5">
                    {images.map((image, index) => {
                        return (
                            <img
                                key={index}
                                className="pageImg rounded"
                                src={image.path}
                                alt={image.path}
                                height={3 * scaleFactor}
                                width={4 * scaleFactor}
                            />

                        )
                    })}
                </div>
            </ShowOnBig>
            <ShowOnSM>
                <div id="carouselExampleFade" className="carousel carousel-fade">
                    <div className="carousel-inner">
                        {
                            images.map((image, index) => {
                                var className = index === 0
                                    ? "carousel-item active"
                                    : "carousel-item"

                                return (
                                    <div className={className} key={index}>
                                        <img
                                            className="pageImg rounded"
                                            src={image.path}
                                            alt={image.path}
                                            height={3 * scaleFactor}
                                            width={4 * scaleFactor}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </ShowOnSM>
        </div>
    )
}